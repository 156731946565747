<template>
  <div class="w-100">
    <!--begin::Content header-->
    <div class="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">

    </div>
    <!--end::Content header-->

    <!--begin::Signin-->
    <div class="row">
      <div class="col-12 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3 col-xxl-4 offset-xxl-4 login-form login-signin">
        <div class="text-center mb-10 mb-lg-20">
          <h3 class="font-size-h1">Reset password</h3>
          <p class="text-muted font-weight-semi-bold">
            Enter your new password.
          </p>
        </div>

        <!-- begin::Error alert -->
        <template v-if="isErrorActive">
          <error-translation-alert
            class="mt-n10 mb-10"
            ref="errorTranslationAlert"
            :error="activeError"
          />
        </template>
        <!-- end::Error alert -->

        <!--begin::Form-->
        <form class="form" @submit.stop.prevent="onSubmit">
          <!-- begin::Password -->
          <div class="form-group">
            <input
              ref="password"
              type="password"
              placeholder="New password"
              :class="['form-control form-control-solid h-auto py-5 px-6', { 'is-invalid': $v.form.password.$error }]"
              :disabled="isLoadingForm"
              v-model="$v.form.password.$model"
            />
          </div>

          <div class="form-group">
            <input
              type="password"
              placeholder="Confirm password"
              :class="['form-control form-control-solid h-auto py-5 px-6', { 'is-invalid': $v.form.password_confirm.$error }]"
              :disabled="isLoadingForm"
              v-model="$v.form.password_confirm.$model"
            />
          </div>
          <!-- end::Password -->

          <!--begin::Action-->
          <div class="form-group d-flex flex-wrap justify-content-between align-items-center">
            <router-link :to="{ path: '/login', query: { email: (form.email || undefined) }}" class="text-dark-60 text-hover-primary my-3 mr-2">
              Back to login
            </router-link>
            <button
              ref="kt_login_signin_submit"
              class="btn btn-primary font-weight-bold px-9 py-4 my-3 font-size-3"
              :disabled="isLoadingForm"
            >
              Submit
            </button>
          </div>
          <!--end::Action-->
        </form>
        <!--end::Form-->
      </div>
      <!--end::Signin-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import { validationMixin } from 'vuelidate';
import { required, sameAs } from 'vuelidate/lib/validators';
import { validations, errorComponentMixin } from '@vedicium/core-vue';
import errorTranslationAlert from '@/components/errors/translation.alert.vue';

import User from '@/libs/classes/user';

export default {
  name: 'authForgotPassword',
  mixins: [validationMixin, errorComponentMixin],
  components: {
    errorTranslationAlert,
  },
  data () {
    return {
      token: null,

      isLoadingForm: false,
      form: {
        password: null,
        password_confirm: null,
      },
    };
  },
  validations: {
    form: {
      password: {
        required,
      },
      password_confirm: {
        required,
        same: sameAs('password'),
      },
    },
  },

  async mounted () {
    this.$set(this, 'token', (this.$route.query ? this.$route.query.token : null));
    await this.$nextTick();
    this.$refs.password.focus();
  },

  methods: {
    async validate () {
      const validationArray = [this.$v];

      // Run the validations
      await validations.validateArray(validationArray);
    },
    async resetForm () {
      this.$set(this, 'form', { email: null });
      await this.$nextTick();
      this.$v.$reset();
    },
    async onSubmit () {
      this.$errors.clear({ component: this });
      this.$set(this, 'isLoadingForm', true);

      // set spinner to submit button
      const submitButton = this.$refs.kt_login_signin_submit;
      submitButton.classList.add('spinner', 'spinner-light', 'spinner-right');

      try {
        await this.validate();
        await this.$ws.put(`${User.uri}/password/reset`, {
          body: {
            token: this.token,
            password: this.form.password,
          },
        }, { skipAuth: true });
      } catch (e) {
        this.$errors.handle(e, { component: this, ui_element: false });
        console.error(e);
        return;
      } finally {
        this.$set(this, 'isLoadingForm', false);
        submitButton.classList.remove('spinner', 'spinner-light', 'spinner-right');
      }

      this.$router.push({ path: '/login' });
    },
  },
};
</script>
